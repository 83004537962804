import React from 'react'

import EADVlogo from '../../../../assets/images/EADV-logo.png'
import arrowDownLarge from '../../../../assets/icons/arrow-down_mobile.png'

import { InformationContainer, InformationImage, InformationTitle, InformationCopy, ArrowDown } from './styles'

const Information = () => {
  const scrollDown = () => {
    window.scrollTo(0, 760);
  }
  return(
    <InformationContainer>
      <InformationImage src={EADVlogo} alt="EADV-logo" />

      <InformationTitle>Welcome to Sanofi Genzyme and Regeneron’s EADV experience</InformationTitle>
      <div style={{ paddingTop: "2.5em" }}></div>

      <InformationCopy>Since 2007, Sanofi Genzyme and Regeneron have collaborated to develop and commercialize fully human monoclonal antibodies utilizing proprietary technologies.</InformationCopy>
      <InformationCopy>We are leading the science behind type 2 inflammation with a first in class moderate-to-severe atopic dermatitis treatment and robust pipeline of potential new medicines for chronic dermatological and respiratory diseases.</InformationCopy>

      <ArrowDown src={arrowDownLarge} alt="arrow-down_large" onClick={() => scrollDown()} />
    </InformationContainer>
  )
}

export default Information
