import React from 'react'
import { Formik } from 'formik';

import { theme, css } from 'twin.macro'
import { CountriesList } from '../../../config/countriesList'

import { Grid } from '../../Blocks/Grid/styles'
import {
  FormContainer,
  InputLeft,
  InputRight,
  SelectRight,
  TextAreaFull,
  SubmitButton,
  ErrorMessage,
  ErrorMessageMobile,
  SubmitMessage
} from './styles'
import PrivacyPolicy from './PrivacyPolicy/privacyPolicy'
import LegalAgreement from './LegalAgreement/legalAgreement'
import LargeButton from '../../Blocks/Buttons/largeBtn'

const Form = ({ setShowExitModal, setExitUrl, setExitModalCopy }) => {
  const numbers = CountriesList;
  const listItems = numbers.map((item, i) => 
    <option value={item.code} key={i}>{item.name}</option>
  );
  return(
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        message: '',
        termsAccepted: false,
      }}
      validate={values => {
        const errors = {}
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email adress';
        }
        if (
            values.country === ""
        ) {
          errors.country = 'Please select a country';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await fetch(process.env.REACT_APP_FORM_URL, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email.toLowerCase(),
            country: values.country,
            message: values.message,
            termsAccepted: values.termsAccepted,
            category: 'lobby',
            projectCode: 'eadv'
          })
        })
        setSubmitting(false);
      }}
      >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitCount,
      }) => (
        <FormContainer action="" onSubmit={handleSubmit}>
        <Grid style={{ marginTop: "1rem" }}>
          <InputLeft
            type="text"
            id="fname"
            name="firstName"
            placeholder="First Name"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
          />
          <InputRight
            type="text"
            id="lname"
            name="lastName"
            placeholder="Last Name"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
          />
        </Grid>

        <Grid style={{ marginTop: "1rem" }}>
          <InputLeft
            type="text"
            id="email"
            name="email"
            placeholder="E-mail"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {errors.email && touched.email && <ErrorMessageMobile>{errors.email}</ErrorMessageMobile>}
          <SelectRight
            id="country"
            name="country"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.country}
            >
            <option hidden value="">Country</option>
            {listItems}
          </SelectRight>
          {errors.email && touched.email && <ErrorMessage>{errors.email}</ErrorMessage>}
          
        </Grid>

        <PrivacyPolicy setShowExitModal={setShowExitModal} setExitUrl={setExitUrl} setExitModalCopy={setExitModalCopy} />

        <LegalAgreement
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.termsAccepted}
          setShowExitModal={setShowExitModal} setExitUrl={setExitUrl} setExitModalCopy={setExitModalCopy}
        />
        <Grid>
          {submitCount === 0 ?
            <SubmitButton>
              <LargeButton
              buttonContent="SUBMIT"
              color={theme`colors.orange`}
              type="submit" 
              disabled={Object.keys(touched).length === 0 || Object.keys(errors).length !== 0}/>
            </SubmitButton> 
          : <>
              <SubmitMessage>Thank you, the form was submitted successfully</SubmitMessage>
            </>
          }
        </Grid>
        </FormContainer>
      )}
      </Formik>
  )
}

export default Form