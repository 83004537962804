import React, { useState } from 'react'
import { theme } from 'twin.macro'

import { TypeTwoCardContainer } from './styles'
import ArrowOrange from '../../../Blocks/Buttons/arrowOrange'

const TypeTwoCard = () => {
  const [hovering, setHovering] = useState(false)
  return(
    <TypeTwoCardContainer href={process.env.REACT_APP_TYPE2_URL} target="_blank" rel="noreferrer" onMouseEnter={() => setHovering(true)} onMouseLeave={() =>setHovering(false)}>
      <p className="typetwocard-title">Experience the Type 2 Inflammation Virtual Booth</p>
      <ArrowOrange hovering={hovering} textColor={theme`colors.white`} hoverColor={theme`colors.royalblue`} />
    </TypeTwoCardContainer>
  )
}

export default TypeTwoCard