import React from 'react'
import { theme } from 'twin.macro'

import sanofiRegeneron from '../../assets/logos/sanofi-regeneron.png'
import blackTriangle from '../../assets/icons/black-triangle.png'

import { Section } from '../Blocks/Section/styles'
import { Grid } from '../Blocks/Grid/styles'
import { FooterTopSection, FooterBottomSection, FooterJobCode } from './styles'

const Footer = () => {
  return(
    <>
      <Section color={theme`colors.bgWhite`}>
        <Grid>
          <FooterTopSection>
            <img src={sanofiRegeneron} alt="sanofiRegeneron" className="footer-logo"/>
            <p>&copy; 2021 Sanofi and Regeneron Pharmaceuticals, Inc. All Rights Reserved.</p>
          </FooterTopSection>
          <FooterBottomSection>
          <img src={blackTriangle} alt="black-triangle" className="footer-black_triangle" /><p><img src={blackTriangle} alt="black-triangle" className="mobile-footer-black_triangle" />This medicinal product is subject to additional monitoring. This will allow quick identification of new safety information. <br />Healthcare professionals are asked to report any suspected adverse reactions.</p>
          </FooterBottomSection>
        </Grid>
      </Section>

      <Section color={theme`colors.bgDarkGrey`}>
        <Grid>
          <FooterJobCode>MAT-GLB-2103485(1.0) <span style={{ marginLeft: "2em" }}>09/2021</span></FooterJobCode>
        </Grid>
      </Section>
    </>
  )
}

export default Footer
