import React, { useState } from 'react'
import { theme } from 'twin.macro'

import { CommercialCardContainer } from './styles'
import ArrowOrange from '../../../Blocks/Buttons/arrowOrange'
import blackTriangle from '../../../../assets/icons/black-triangle.png'

const CommercialCard = () => {
  const [hovering, setHovering] = useState(false)
  return(
    <CommercialCardContainer href={process.env.REACT_APP_COMMERCIAL_URL} target="_blank" rel="noreferrer" 
    onMouseEnter={() => setHovering(true)} onMouseLeave={() =>setHovering(false)} onTouchStart={() => setHovering(true)} onTouchEnd={() =>setHovering(false)}>
      {!hovering ? (
        <p className="commercialcard-title">Explore the Product<br/>Virtual Booth Experience</p>
      ): (
        <p className="commercialcard-title">Explore the DUPIXENT <img src={blackTriangle} className="black-triangle" /> (dupilumab)<sup>&reg;</sup> virtual booth experience</p>
      )}
      <ArrowOrange hovering={hovering} textColor={theme`colors.white`} hoverColor={theme`colors.grey`} />
    </CommercialCardContainer>
  )
}

export default CommercialCard
