import React from 'react'

import { Grid } from '../Blocks/Grid/styles'
import { ShowInMobile, ShowInDesktop, TopSectionContainer } from './styles'

import Information from './ContentBlocks/Information/information'
import CommercialCard from './ContentBlocks/CommercialCard/commercialCard'
import TypeTwoCard from './ContentBlocks/TypeTwoCard/typeTwoCard'
import MedicalAffairs from './ContentBlocks/MedicalAffairs/medicalAffairs'
import Product from './ContentBlocks/Product/product'
import MedicalInformation from './ContentBlocks/MedicalInformation/medicalInformation'

const TopSection = ({ setShowModal, setShowExitModal, setPdf, setExitUrl, setExitModalCopy }) => {
  return(
    <TopSectionContainer>
      {/* SHOWS IN MOBILE ONLY */}
      <ShowInMobile>
        <Grid className="topsection_grid skyblue">
          <Product setShowModal={setShowModal} setPdf={setPdf} />
          <CommercialCard />
          <Information />
        </Grid>

        <Grid className="topsection_grid grey">
          <MedicalAffairs setShowExitModal={setShowExitModal} setExitUrl={setExitUrl} setExitModalCopy={setExitModalCopy} />
          <TypeTwoCard />

        </Grid>

        <Grid className="topsection_grid grey">
          <MedicalInformation setShowExitModal={setShowExitModal} setExitUrl={setExitUrl} setExitModalCopy={setExitModalCopy} />
        </Grid>
      </ShowInMobile>

      {/* SHOWS IN DESKTOP ONLY */}
      <ShowInDesktop>
        <Grid className="topsection_grid">
          <CommercialCard />
          <Information />
          <TypeTwoCard />
          <MedicalAffairs setShowExitModal={setShowExitModal} setExitUrl={setExitUrl} setExitModalCopy={setExitModalCopy} />
          <Product setShowModal={setShowModal} setPdf={setPdf} />

        </Grid>
      </ShowInDesktop>
    </TopSectionContainer>
  )
}

export default TopSection
