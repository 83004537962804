import styled from 'styled-components/macro';
import tw, { theme } from 'twin.macro'

import typeTwoBoothCardBg from '../../../../assets/images/typetwo-booth-card-bg.png'

export const TypeTwoCardContainer = styled.a`
  ${tw`col-span-4 order-4 lg:col-span-4 lg:order-4`};
  background: ${theme`colors.blue`};
  border-radius: 12px;
  box-shadow: 2px 2px 10px 5px #A4A4A4;
  display: flex;
  height: 300px;
  justify-content: center;
  padding: 1em;
  position: relative;
  transition: 0.5s;
  max-width: 250px;
  margin: 3em auto 2em auto;

  @media screen and (min-width: 1024px) {
    margin: 0 auto;
  }
  
  .typetwocard-title {
    color: ${theme`colors.white`};
    font-size: 32px;
    font-weight: bold;
  }

  :hover {
    background-image: url(${typeTwoBoothCardBg});
    background-color: white;
    background-size: stretch;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    
    .typetwocard-title {
      color: ${theme`colors.blue`};
    }
  }
`
