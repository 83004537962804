import React, { useState } from 'react';

import { theme } from 'twin.macro'

import { Grid } from '../../Blocks/Grid/styles'
import { IntroModalContainer, IntroModalOuter, IntroModalInner, IntroModalWelcome, IntroModalCopy, IntroModalDisclaimer, IntroModalBtns } from './styles'
import LargeBtn from '../../Blocks/Buttons/largeBtn'

const IntroModal = ({ setShowSite }) => {
  const cancelRedirect = () => {
    window.location.href = "https://www.sanofigenzyme.com/";
  }

  const sitePreviouslyVisited = () => {
    console.log("add site previously visited cookie storage");
  }

  return(
    <>
      <IntroModalContainer>
        <IntroModalOuter>
          <Grid>
            <IntroModalInner>
                <IntroModalWelcome>Welcome</IntroModalWelcome><br/>
                <IntroModalCopy>This website is intended for healthcare professionals only and is not intended for the general public.</IntroModalCopy>
                <IntroModalCopy>The content of this website is based on the EU SmPC which can be accessed by clicking <a style={{ lineBreak: "anywhere",color: "white" }} href="https://www.ema.europa.eu/en/documents/product-information/dupixent-epar-product-information_en.pdf" target="_blank" rel="noreferrer"><u>here</u></a></IntroModalCopy><br/>
                <IntroModalCopy>Sanofi does not recommend the use of its products in any manner inconsistent with that described in the label available in your country.</IntroModalCopy>
                <IntroModalCopy>Please refer to your local product labelling information before prescribing.</IntroModalCopy><br/>
                <IntroModalCopy>09/2021 MAT-GLB-2103485(1.0)</IntroModalCopy>
                <IntroModalBtns>
                  <LargeBtn color={theme`colors.white`} secondaryColor={theme`colors.orange`} buttonContent={"CANCEL"} onClick={() => cancelRedirect()} />
                  <LargeBtn color={theme`colors.orange`} buttonContent={"I AM A HEALTHCARE PROFESSIONAL"} onClick={() => { setShowSite(true); sitePreviouslyVisited(); }} />
                </IntroModalBtns>
            </IntroModalInner>
          </Grid>
        </IntroModalOuter>
      </IntroModalContainer>
    </>

  )
}

export default IntroModal
