import React, { useState } from 'react'
import { theme } from 'twin.macro'

import { MedicalAffairsContainer, MedicalAffairsShape, MedicalAffairsText } from './styles'
import ArrowOrange from '../../../Blocks/Buttons/arrowOrange'

const MedicalAffairs = ({ setShowExitModal, setExitUrl, setExitModalCopy }) => {
  const [hovering, setHovering] = useState(false)

  const exitCopy = "You are being redirected to Regeneron’s Medical Information page. This website is hosted by Regeneron Pharmaceuticals Inc."

  return(
    <MedicalAffairsContainer onClick={() => {
      setShowExitModal(true)
      setExitUrl("https://sgzrgn-medicalaffairscongress.com/EADV2021")
      setExitModalCopy(exitCopy)
      }} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <MedicalAffairsShape hovering={hovering} />
      {!hovering ? (
        <MedicalAffairsText hovering={hovering}>Visit our<br/>Medical Affairs area</MedicalAffairsText>
      ) : (
        <MedicalAffairsText hovering={hovering} className="medicalaffairs_hover">Posters<br />Scientific Information<br />Medical education</MedicalAffairsText>
      )}
      <ArrowOrange hovering={hovering} textColor={theme`colors.orange`} hoverColor={theme`colors.white`} />
    </MedicalAffairsContainer>
  )
}

export default MedicalAffairs
