import React from 'react'
import styled from 'styled-components/macro'
import tw, { theme } from 'twin.macro'

import { Grid } from '../../../Blocks/Grid/styles'

const PrivacyPolicyContainer = styled.div`
  ${tw`col-span-4 lg:col-span-8 lg:col-start-3`};
  background: white;
  color: ${theme`colors.lightgrey`};
  border-radius: 2px;
  margin: 1rem 0;
  padding: 1rem;
  text-align: left;

  .privacypolicy-title {
    font-size: 14px;
    font-weight: bold;
  }

  .privacypolicy-copy {
    font-size: 12px;
    margin-top: 0.6rem;
  }

  .privacypolicy-copy__anchor {
    color: ${theme`colors.orange`};
    cursor: pointer;
    text-decoration: underline;
  }
`

const PrivacyPolicy = () => {
  return(
    <Grid>
      <PrivacyPolicyContainer>
        <p className="privacypolicy-title">INFORMATION & CONSENT FOR THE PROCESSING OF PERSONAL DATA</p>
        <p className="privacypolicy-copy">If you consent to receiving marketing/promotional and medical educational communications, Sanofi-Aventis Group and its <a href='https://www.sanofi.com/en/our-responsibility/sanofi-global-privacy-policy/list-of-sanofi-affiliates' target='_blank' rel="noreferrer">affiliates</a> will need to process your personal data to be able to send you personalized commercial and medical communications. To do this, Sanofi processes your interests, preferences and interactions in order to adapt these communications. For instance, the e-mails sent to you are equipped with click rating to determine whether a topic is of interest to you. It is also used to stop sending you messages where you have not opened any Sanofi communications for several months.</p>
        <p className="privacypolicy-copy">To that end, your personal data may be communicated to processors used by Sanofi and/or to Sanofi’s alliance partner, Regeneron Pharmaceuticals Inc. Where these communications imply transfers of data outside of the region of collection, adequate safeguards are implemented such as Standards Contractual Clauses.</p>
        <p className="privacypolicy-copy">Sanofi will keep these personal data as long as it has interactions with you, but no longer than 3 years after our last interaction, unless you withdraw your consent.</p>
        <p className="privacypolicy-copy">According to applicable data protection law, you may have the rights to withdraw your consent via the methods outlined in the <a href='https://www.sanofi.com/en/our-responsibility/sanofi-global-privacy-policy/list-of-sanofi-affiliates' target='_blank' rel="noreferrer">Privacy Policy</a>. You can ask access or portability to your personal data, ask for their rectification or erasure or restrict their processing. To exercise these rights, please <a href='https://www.sanofi.com/en/our-responsibility/sanofi-global-privacy-policy/contact' target='_blank' rel='noreferrer'>click here</a>.</p>
      </PrivacyPolicyContainer>
    </Grid>
  )
}

export default PrivacyPolicy