import styled from 'styled-components/macro';
import tw, { theme } from 'twin.macro'

export const FooterTopSection = styled.div`
  ${tw`col-span-4 lg:col-span-12`};
  align-items: center;
  display: grid;
  margin: 40px 0;
  
  @media screen and (min-width: 1024px) {
    display: flex;
    height: 100px;
    justify-content: space-between;
    margin: 0;
  }
  
  img {
    margin: 0 auto 30px auto;
    width: 100%;
    max-width: 400px;
    
    @media screen and (min-width: 1024px) {
      margin: 0;
      width: 400px;
    }
  }

  p {
    color: ${theme`colors.royalblue`};
    font-size: 14px;
    text-align: center;
    
    @media screen and (min-width: 1024px) {
      font-size: 16px;
    }
  }
`

export const FooterBottomSection = styled.div`
  ${tw`col-span-4 lg:col-span-12`};
  display: flex;
  margin-bottom: 40px;
  text-align: center;

  br {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    text-align: left;

    br {
      display: block;
    }
  }

  p {
    font-weight: bold;
    font-size: 15px;
  }

  .footer-black_triangle {
    display: none; 

    @media screen and (min-width: 1024px) {
      height: 28px;
      width: 32px;
      margin: 4px 10px 0 0;
      display: block;
    }
  }

  .mobile-footer-black_triangle {
    height: 16px;
    width: 20px;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
`

export const FooterJobCode = styled.div`
  ${tw`col-span-4 lg:col-span-12`};
  color: ${theme`colors.white`};
  font-size: 14px;
  padding: 20px 0;
  text-align: center;

  @media screen and (min-width: 1024px) {
    text-align: left;
  }
`