import React from 'react'
import { theme } from 'twin.macro'

import { Grid } from '../../Blocks/Grid/styles'
import { ExitModalContainer, ExitModalOuter, ExitModalInner, ExitModalWelcome, ExitModalCopy, ExitModalBtns } from './styles'
import LargeBtn from '../../Blocks/Buttons/largeBtn'
import useLockBodyScroll from '../../../utils/useLockBodyScroll'

const ExitModal = ({ exitUrl, setShowExitModal, exitModalCopy }) => {
  const exitSite = () => {
    window.open(exitUrl, '_blank');
  }
  useLockBodyScroll();

  return(
    <ExitModalContainer>
      <ExitModalOuter>
        <Grid>
          <ExitModalInner>
              <ExitModalWelcome>You are now leaving this website</ExitModalWelcome>
              <ExitModalCopy>{exitModalCopy}</ExitModalCopy>
              <ExitModalCopy>09/2021 MAT-GLB-2103485(1.0)</ExitModalCopy>
              <ExitModalBtns>
                <LargeBtn color={theme`colors.white`} secondaryColor={theme`colors.orange`} buttonContent={"CANCEL"} onClick={() => { setShowExitModal(false) }} />
                <LargeBtn color={theme`colors.orange`} buttonContent={"CONTINUE"} onClick={() => { exitSite(); setShowExitModal(false) }} />
              </ExitModalBtns>
          </ExitModalInner>
        </Grid>
      </ExitModalOuter>
    </ExitModalContainer>
  )
}

export default ExitModal
