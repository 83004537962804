import styled from 'styled-components/macro';
import tw, { theme } from 'twin.macro' 

export const LegalAgreementCheckbox = styled.div`
  ${tw`col-span-12 lg:col-span-8 lg:col-start-3`};

  margin: 0 auto 2em auto;
  color: ${theme`colors.white`};
  font-size: 14px;
  text-align: left;

  .legalagreement-copy__anchor {
    color: ${theme`colors.orange`};
    cursor: pointer;
    text-decoration: underline;
  }

  [type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: white;
  }
  [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: ${theme`colors.orange`};
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`