import styled from 'styled-components/macro';
import tw, { theme } from 'twin.macro'

import commercialBoothCardBg from '../../../../assets/images/commercial-booth-card-bg.png'

export const CommercialCardContainer = styled.a`
  ${tw`col-span-4 order-2 lg:col-span-4 lg:order-1`};
  background: ${theme`colors.royalblue`};
  border-radius: 12px;
  box-shadow: 2px 2px 10px 5px #A4A4A4;
  display: flex;
  height: 300px;
  justify-content: center;
  padding: 1em;
  position: relative;
  transition: 0.5s;
  max-width: 250px;
  margin: 1em auto 1em auto;

  .black-triangle {
    width: 14px;
    height: 12px;
    vertical-align: top;
    margin-top: 6px;
  }

  @media screen and (min-width: 1024px) {
    margin: 0 auto;
  }
  .commercialcard-title {
    color: ${theme`colors.white`};
    font-size: 32px;
    font-weight: bold;
  }

  :hover {
    background-image: url(${commercialBoothCardBg});
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom left;
    cursor: pointer;
    
    .commercialcard-title {
      color: ${theme`colors.green`};
    }
  }
`